import { clsx } from "clsx";
import styles from "./Skeleton.module.css";

const DISPLAY_NAME = "Nid.Skeleton";

export type SkeletonProps = {
  loading?: boolean;
  width?: number | string;
  height?: number | string;
  radius?: number | string;
};

const Skeleton = ({
  loading = true,
  width = "100%",
  height = "8px",
  radius = "4px",
}: SkeletonProps) => {
  const style = {
    width,
    height,
    borderRadius: radius,
  };

  return loading ? (
    <div className={clsx(styles.skeleton)} style={style} />
  ) : null;
};

Skeleton.displayName = DISPLAY_NAME;

const Root = Skeleton;

export { Skeleton, Root };
export default Skeleton;
