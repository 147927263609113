import type React from "react";

import { Link as RouterLink } from "react-router";
import { Typography, type TypographyProps } from "../Typography";

export type LinkProps = Omit<TypographyProps, "color" | "as"> & {
  href?: string;
  to?: string;
  newTab?: boolean;
  type?: string;
  state?: unknown;
  className?: string;
  linkClassName?: string;
  "data-testid"?: string;
};

export const Link: React.FC<LinkProps> = ({
  href,
  to,
  newTab = false,
  className,
  linkClassName,
  children,
  onClick,
  "data-testid": dataTestId,
  ...rest
}) => {
  const linkProps = newTab
    ? { target: "_blank", rel: "noopener noreferrer" }
    : { rel: "noreferrer" };

  const inner = (
    <Typography
      className={className}
      as={"span"}
      color={"link"}
      {...rest}
      data-testid={undefined}
    >
      {children}
    </Typography>
  );

  return to ? (
    <RouterLink
      to={to}
      onClick={onClick}
      className={linkClassName}
      state={rest.state}
      data-testid={dataTestId}
      {...linkProps}
    >
      {inner}
    </RouterLink>
  ) : (
    <a
      onClick={onClick}
      href={href}
      data-testid={dataTestId}
      className={linkClassName}
      {...linkProps}
    >
      {inner}
    </a>
  );
};
