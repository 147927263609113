import type { AxiosError } from "axios";
import { deletePasskey } from "../api/account";
import { useWebAuthn } from "./useWebAuthn";

type PasskeyErrorDetails = {
  message: string;
  code: "login_required" | "server_error" | "invalid_request" | "unknown_error";
  data?: unknown; // 追加: エラー詳細用のフィールド
};

export const useRevokePasskey = () => {
  const { signalUnknownCredential, signalAllAcceptedCredentials } =
    useWebAuthn();

  const revokePassKey = async (id: string) => {
    try {
      const response = await deletePasskey({ id });

      try {
        if (response.data?.credential_ids && response.data?.user_id) {
          await signalAllAcceptedCredentials({
            rpId: window.location.hostname,
            userId: response.data.user_id,
            allAcceptedCredentialIds: response.data.credential_ids,
          });
        } else if (response.data?.credential_id) {
          await signalUnknownCredential({
            credentialId: response.data.credential_id,
            rpId: window.location.hostname,
          });
        }
      } catch (signalError) {
        console.warn(
          "Failed to signal all accepted credential to browser:",
          signalError,
        );
      }
    } catch (e) {
      const response = (e as AxiosError).response;
      if (response?.status === 400) {
        throw {
          message: "invalid request",
          code: "invalid_request",
          data: response?.data,
        } as PasskeyErrorDetails;
      }
      throw {
        message: "Network or server error occurred",
        code: "server_error",
        data: response?.data,
      } as PasskeyErrorDetails;
    }
  };

  return {
    revokePassKey,
  };
};
