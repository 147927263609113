import type React from "react";

import { Box } from "../../../foundations/Box";

import clsx from "clsx";
import styles from "./DropdownIcon.module.css";

export const DropdownIcon: React.FC<React.ComponentProps<"svg">> = (props) => {
  const { className, ...others } = props;
  return (
    <Box className="nid-icon-container-large">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Dropdown"
        role="img"
        className={clsx(styles.gray90, className)}
        {...others}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 9L12 15.4286L17 9H7Z"
        />
      </svg>
    </Box>
  );
};
