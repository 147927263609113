import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_CONTRACT_CANCEL } from "./apiPaths";

export type ContractCancelRequest = {
  plan_id: string;
  answer1?: "0" | "1";
  answer2?: "0" | "1";
  answer3?: "0" | "1";
  answer4?: "0" | "1";
  answer5?: "0" | "1";
  answer6?: "0" | "1";
  answer7?: "0" | "1";
  answer8?: "0" | "1";
  answer9?: "0" | "1";
  answer10?: "0" | "1";
  answer_text?: string;
};

const getUrl = (serviceId: string): string => {
  return ACCOUNT_API_CONTRACT_CANCEL.replace("<client_id>", serviceId);
};

export type ContractCancelErrorResponse = {
  error?: "nkdk_inactive" | "server_error" | undefined;
};

export const postContractCancel = (
  clientId: string,
  body: ContractCancelRequest,
): Promise<AxiosResponse> => {
  return axios.post(getUrl(clientId), body, {
    withCredentials: true,
  });
};
