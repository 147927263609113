import { useCallback, useEffect, useRef } from "react";

const CUSTOM_BODY_CLASS_NAME = "modal-overflow-hidden";

export const useModal = (params?: {
  onClose?: () => Promise<void> | void;
}) => {
  const ref = useRef<HTMLDialogElement>(null);

  const openModal = useCallback(() => {
    if (ref.current?.showModal) {
      document.body.classList.add(CUSTOM_BODY_CLASS_NAME);
      ref.current.showModal();
    }
  }, []);

  const closeModal = useCallback(() => {
    if (ref.current?.close) {
      ref.current.close();
      document.body.classList.remove(CUSTOM_BODY_CLASS_NAME);
    }
  }, []);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
      if (event.target === ref.current) {
        ref.current.close();
      }
    },
    [],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDialogElement>) => {
      if (event.key === "Escape") {
        if (ref.current?.close) {
          ref.current.close();
        }
      }
    },
    [],
  );

  const onClose = useCallback(async () => {
    await params?.onClose?.();
    document.body.classList.remove(CUSTOM_BODY_CLASS_NAME);
  }, [params?.onClose]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `.${CUSTOM_BODY_CLASS_NAME} { overflow: hidden; }`;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
      onClose();
    };
  }, []);

  return {
    registerModal: { ref, onClick, onKeyDown, onClose },
    openModal,
    closeModal,
  };
};
