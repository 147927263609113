import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";

type WebAuthenticationAPIGetRequest = {
  publicKey: PublicKeyCredentialRequestOptions;
  signal?: AbortSignal;
  mediation?: CredentialMediationRequirement;
};

type WebAuthenticationAPICreateRequest = {
  publicKey: PublicKeyCredentialCreationOptions;
  signal?: AbortSignal;
};

declare global {
  interface PublicKeyCredentialStatic {
    // Signal APIs
    signalUnknownCredential(
      options: SignalUnknownCredentialOptions,
    ): Promise<void>;

    signalAllAcceptedCredentials(
      options: SignalAllAcceptedCredentialsOptions,
    ): Promise<void>;

    isConditionalMediationAvailable?(): Promise<boolean>;
  }
}

type SignalUnknownCredentialOptions = {
  credentialId: string;
  rpId?: string;
};

type SignalAllAcceptedCredentialsOptions = {
  rpId: string;
  userId: string;
  allAcceptedCredentialIds: string[];
};

type WebAuthenticationAPI = {
  getPublicKeyCredential: (
    options: WebAuthenticationAPIGetRequest,
  ) => Promise<PublicKeyCredential | null>;

  createPublicKeyCredential: (
    options: WebAuthenticationAPICreateRequest,
  ) => Promise<PublicKeyCredential | null>;

  signalUnknownCredential: (
    options: SignalUnknownCredentialOptions,
  ) => Promise<void>;

  signalAllAcceptedCredentials: (
    options: SignalAllAcceptedCredentialsOptions,
  ) => Promise<void>;

  isPasskeysAvailable: boolean;
  isConditionalMediationAvailable: boolean | undefined;
};

export const useWebAuthn = (): WebAuthenticationAPI => {
  const { data: isConditionalMediationAvailable } = useSWRImmutable(
    "/PublicKeyCredential/isConditionalMediationAvailable",
    async () => PublicKeyCredential?.isConditionalMediationAvailable?.(),
  );
  const getPublicKeyCredential = useCallback(
    async (
      request: WebAuthenticationAPIGetRequest,
    ): Promise<PublicKeyCredential | null> => {
      const credential = await navigator.credentials.get({
        signal: request.signal,
        mediation: request.mediation,
        publicKey: request.publicKey,
      });

      return credential !== null ? (credential as PublicKeyCredential) : null;
    },
    [],
  );

  const createPublicKeyCredential = useCallback(
    async (
      request: WebAuthenticationAPICreateRequest,
    ): Promise<PublicKeyCredential | null> => {
      const credential = await navigator.credentials.create({
        signal: request.signal,
        publicKey: request.publicKey,
      });

      return credential !== null ? (credential as PublicKeyCredential) : null;
    },
    [],
  );

  const signalUnknownCredential = useCallback(
    async (options: SignalUnknownCredentialOptions): Promise<void> => {
      if (
        typeof window !== "undefined" &&
        window.PublicKeyCredential &&
        "signalUnknownCredential" in PublicKeyCredential
      ) {
        await (
          PublicKeyCredential as unknown as PublicKeyCredentialStatic
        ).signalUnknownCredential(options);
      }
    },
    [],
  );

  const signalAllAcceptedCredentials = useCallback(
    async (options: SignalAllAcceptedCredentialsOptions): Promise<void> => {
      if (
        typeof window !== "undefined" &&
        window.PublicKeyCredential &&
        "signalAllAcceptedCredentials" in PublicKeyCredential
      ) {
        await (
          PublicKeyCredential as unknown as PublicKeyCredentialStatic
        ).signalAllAcceptedCredentials(options);
      }
    },
    [],
  );

  return {
    isConditionalMediationAvailable,
    isPasskeysAvailable: window.PublicKeyCredential !== undefined,
    getPublicKeyCredential,
    createPublicKeyCredential,
    signalUnknownCredential,
    signalAllAcceptedCredentials,
  };
};
