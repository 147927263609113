import { Skeleton } from "./Skeleton";

const DISPLAY_NAME = "Nid.SkeletonText";

const heightPresets = {
  tiny: "8px",
  small: "12px",
  medium: "16px",
  large: "20px",
};

const radiusPresets = {
  tiny: "4px",
  small: "6px",
  medium: "8px",
  large: "10px",
};

export type SkeletonTextProps = {
  loading?: boolean;
  lineCount?: number;
  lineGap?: number | string;
  size?: "tiny" | "small" | "medium" | "large";
};

const SkeletonText = ({
  loading = true,
  lineCount = 1,
  lineGap = "26px",
  size = "tiny",
}: SkeletonTextProps) => {
  const height = heightPresets[size];
  const radius = radiusPresets[size];

  return (
    <div>
      {Array.from({ length: lineCount }).map((_, index) => (
        <div
          style={lineCount > 1 ? { marginBottom: lineGap } : undefined}
          // biome-ignore lint/suspicious/noArrayIndexKey: SkeletonTextは静的な順番のためindexをキーにしても問題ない
          key={index}
        >
          <Skeleton loading={loading} height={height} radius={radius} />
        </div>
      ))}
    </div>
  );
};

SkeletonText.displayName = DISPLAY_NAME;

const Root = SkeletonText;

export { SkeletonText, Root };
export default SkeletonText;
