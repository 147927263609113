import * as React from "react";

import { clsx } from "clsx";

import { Typography } from "../../..";
import { Box } from "../../../foundations/Box";

import type { FormValidationStatus } from "../../../utils/types";

const DISPLAY_NAME = "Nid.Radio";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type RadioTileProps = {
  label: React.ReactNode;
  subText?: string;
  name: string;
  id: string;
  value: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  status?: FormValidationStatus;
} & InputWithRefProps;

const useRadioState = (props: RadioTileProps) => {
  const { name, label, className, id, status, subText, ...rest } = props;
  return { ...rest };
};

const RadioTile = React.forwardRef<HTMLInputElement, RadioTileProps>(
  (props, forwardedRef) => {
    const nidContainerClasses = clsx(
      props.className,
      "nid-radio",
      "nid-radio-type-tile",
      props.disabled && "nid-radio-disabled",
      props.status ? `nid-radio-${props.status}` : undefined,
      props.checked && "nid-radio-checked",
    );

    const state = useRadioState(props);
    const isDisabled = props.disabled || false;

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleChange = () => {
      if (inputRef.current && !isDisabled) {
        inputRef.current.checked = true;
        inputRef.current.focus();
        if (state.onChange) {
          const event = new Event("change", { bubbles: true });
          Object.defineProperty(event, "target", {
            value: inputRef.current,
            enumerable: true,
          });
          state.onChange(
            event as unknown as React.ChangeEvent<HTMLInputElement>,
          );
        }
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleChange();
      }
    };

    const handleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      handleChange();
    };

    React.useImperativeHandle(
      forwardedRef,
      () => inputRef.current as HTMLInputElement,
    );

    return (
      <fieldset disabled={isDisabled} className={nidContainerClasses}>
        <Box
          className="nid-radio-type-tile-box nid-button-border"
          data-testid={`${props.id}-label`}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={handleClick}
        >
          <Box as="div" className="nid-radio-radio">
            <input
              type="radio"
              name={props.name}
              className="nid-radio-type-tile-radio"
              id={props.id}
              data-testid={`${props.id}-radio`}
              ref={inputRef}
              tabIndex={-1}
              {...state}
            />
          </Box>
          <Box className="nid-radio-type-tile-textbox">
            <Typography as="div">{props.label}</Typography>
            {props.subText && (
              <Typography as="span" size={"14"} color={"gray90"}>
                {props.subText}
              </Typography>
            )}
          </Box>
        </Box>
      </fieldset>
    );
  },
) as React.ForwardRefExoticComponent<RadioTileProps>;

RadioTile.displayName = DISPLAY_NAME;
const Root = RadioTile;

export { RadioTile, Root };
export type { RadioTileProps };
export default RadioTile;
