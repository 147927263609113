import clsx from "clsx";
import React from "react";
import type { ReactNode } from "react";
import { Box, Typography } from "../..";
import styles from "./Modal.module.css";

interface ModalProps {
  title?: string;
  children: ReactNode;
  testId?: string;
  onClose: () => void;
  onClick: (event: React.MouseEvent<HTMLDialogElement, MouseEvent>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDialogElement>) => void;
  className?: string;
}

const Modal = React.forwardRef<HTMLDialogElement, ModalProps>(
  (
    { title, onClose, onClick, onKeyDown, children, testId, className },
    ref,
  ) => {
    const classNames = clsx(styles.dialogBase, className || styles.dialog);
    return (
      <dialog
        ref={ref}
        onClose={onClose}
        className={classNames}
        data-testid={testId}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <Box>
          {title && (
            <Typography
              as="h4"
              className={"mb-6"}
              data-testid={`${testId}-title`}
              bold
            >
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </dialog>
    );
  },
);

Modal.displayName = "Nid.Modal";
const Root = Modal;
export { Modal, Root };
export default Modal;
