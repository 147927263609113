import type React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";

import styles from "./SpinnerIcon.module.css";

type SpinnerIconProps = React.ComponentProps<"svg"> & {
  width?: number | string;
  height?: number | string;
  insideTypography?: boolean;
};

export const SpinnerIcon: React.FC<SpinnerIconProps> = (props) => {
  const className = clsx(props.className, "nid-spinner", styles.gray120);
  const containerClassName = props.insideTypography
    ? "nid-icon-container-end-adornment"
    : "nid-icon-container";

  return (
    <Box className={containerClassName}>
      <svg
        width={props.width ?? "16px"}
        height={props.height ?? "16px"}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        data-testid="nid-spinner"
        aria-label="Now Loading..."
        role="img"
      >
        <circle cx="8" cy="8" r="7" strokeWidth="2" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2V0C9.58225 0 11.129 0.469191 12.4446 1.34824C12.8555 1.62285 13.2382 1.93361 13.5886 2.27569C12.1466 0.867667 10.1746 0 8 0C3.58172 0 0 3.58172 0 8C0 10.1466 0.845434 12.0957 2.22149 13.5325C1.90072 13.1975 1.60828 12.8337 1.34824 12.4446C0.469192 11.129 0 9.58225 0 8H2C2 4.68629 4.68629 2 8 2ZM2.41141 13.7243C3.84305 15.1222 5.79706 15.9875 7.95314 15.9999C6.92339 15.9938 5.89927 15.789 4.93853 15.391C3.99307 14.9994 3.13644 14.4321 2.41141 13.7243ZM8.05523 15.9998C12.4297 15.9702 15.9702 12.4297 15.9998 8.05523C15.9963 8.55842 15.9454 9.06249 15.8463 9.56072C15.5376 11.1126 14.7757 12.538 13.6569 13.6569C12.538 14.7757 11.1126 15.5376 9.56072 15.8463C9.06249 15.9454 8.55842 15.9963 8.05523 15.9998ZM15.9999 7.95314C15.9876 5.81858 15.1394 3.88208 13.766 2.45444C14.4544 3.17025 15.0071 4.01171 15.391 4.93853C15.789 5.89927 15.9938 6.92339 15.9999 7.95314Z"
        />
      </svg>
    </Box>
  );
};
