import { useLocation } from "react-router";
import { ACCOUNT_API_SIGN_IN_CALLBACK, getStartSignIn } from "../api/account";
import { OIDC_API_AUTHORIZATION } from "../api/connect";
import { useLeaveApp } from "./useLeaveApp";

type SignInProps = {
  replace: boolean;
  maxAge?: number;
  loginHint?: string;
  prompt?: "create";
};

export const useSignIn = () => {
  const redirectUrl = `${import.meta.env.BASE_URL}${useLocation().pathname}${useLocation().search}`;
  const { redirect } = useLeaveApp();

  const startSignIn = async ({
    maxAge,
    loginHint,
    prompt,
    replace,
  }: SignInProps) => {
    const response = await getStartSignIn(redirectUrl);
    const authorizationUri = `${import.meta.env.VITE_ORIGIN}${OIDC_API_AUTHORIZATION}`;
    const redirectUri = `${import.meta.env.VITE_ORIGIN}${ACCOUNT_API_SIGN_IN_CALLBACK}`;
    const queryParams = new URLSearchParams({
      scope: "openid",
      response_type: "code",
      client_id: "NID",
      redirect_uri: redirectUri,
      code_challenge: response.data.code_challenge,
      code_challenge_method: "S256",
      state: response.data.state,
      ...(prompt && { prompt }),
      ...(maxAge && { max_age: maxAge.toString() }),
      ...(loginHint && {
        login_hint: loginHint,
        login_hint_fix: "true",
      }),
    });

    redirect(`${authorizationUri}?${queryParams.toString()}`, replace);
  };

  return {
    startSignIn,
  };
};
