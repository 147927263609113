import type { AxiosResponse } from "axios";
import axios from "axios";
import { ACCOUNT_API_PAYMENTS_CARDS } from "./apiPaths";

export type PostUpdateCreditCardRequest = {
  new_card_id: string;
  old_card_id: string;
  service_id?: string;
};

export type PostUpdateCreditCardErrorResponse = {
  error: string;
};

export const postUpdateCreditCard = (
  body: PostUpdateCreditCardRequest,
): Promise<AxiosResponse> => {
  return axios.post(ACCOUNT_API_PAYMENTS_CARDS, body, {
    withCredentials: true,
  });
};
