import type { Provider, ResolutionDetails } from "@openfeature/web-sdk";

/**
 * Cookie と環境変数を利用して Feature Flag を管理する OpenFeature Provider
 *
 * - **優先順位:** Cookie > 環境変数 > デフォルト値
 * - **Cookie のプレフィックス:** `"NID_FEATURE_FLAG_<FLAG_KEY>"`
 * - **環境変数のプレフィックス:** `"VITE_FEATURE_FLAG_<FLAG_KEY>"`
 *
 * `useBooleanFlagValue("<FLAG_KEY>", false)` で Feature Flag の値を取得できる
 * 優先順位は Cookie > 環境変数 > デフォルト値
 */
export class NidFeatureFlagProvider implements Provider {
  readonly metadata = { name: this.name } as const;

  constructor(
    private cookie_prefix = "NID_FEATURE_FLAG_",
    private env_prefix = "VITE_FEATURE_FLAG_",
    private name = "NID Feature Flag Provider",
  ) {}

  private getCookieValue(flagKey: string): string | null {
    const prefixedKey = this.cookie_prefix + flagKey;
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === prefixedKey) return decodeURIComponent(value);
    }
    return null;
  }

  private getEnvVarValue(flagKey: string): string | null {
    return import.meta.env[this.env_prefix + flagKey] ?? null;
  }

  private getValue(flagKey: string): string | null {
    const cookieValue = this.getCookieValue(flagKey);
    if (cookieValue !== null && cookieValue !== "") return cookieValue;
    const envVarValue = this.getEnvVarValue(flagKey);
    if (envVarValue !== null && envVarValue !== "") return envVarValue;
    return null;
  }

  resolveBooleanEvaluation(
    flagKey: string,
    defaultValue: boolean,
  ): ResolutionDetails<boolean> {
    const value = this.getValue(flagKey);
    return { value: value !== null ? value === "true" : defaultValue };
  }

  resolveStringEvaluation(
    flagKey: string,
    defaultValue: string,
  ): ResolutionDetails<string> {
    const value = this.getValue(flagKey);
    return { value: value ?? defaultValue };
  }

  resolveNumberEvaluation(
    flagKey: string,
    defaultValue: number,
  ): ResolutionDetails<number> {
    const value = this.getValue(flagKey);
    return { value: value ? Number(value) : defaultValue };
  }

  resolveObjectEvaluation<T>(
    flagKey: string,
    defaultValue: T,
  ): ResolutionDetails<T> {
    const value = this.getValue(flagKey);
    return { value: value ? JSON.parse(value) : defaultValue };
  }
}
