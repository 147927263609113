import { type AxiosError, isAxiosError } from "axios";
import {
  type PasskeyResponse,
  getCreationOptionsPasskeys,
  postCreatePasskey,
} from "../api/account/postCreatePasskey";
import { AtlasTracking } from "../lib/atlasTracking";
import {
  parseCreationOptionsFromJSON,
  toRegistrationResponseJson,
} from "../lib/webauthn";
import { useWebAuthn } from "./useWebAuthn";

export type PasskeySuccessResponse = {
  code: "success";
  data: PasskeyResponse;
};

export type PasskeyErrorDetails = {
  message: string;
  code:
    | "user_cancel"
    | "login_required"
    | "server_error"
    | "invalid_request"
    | "already_registered"
    | "webauthn_error"
    | "unknown_error";
  data?: unknown; // 追加: エラー詳細用のフィールド
};

/**
 * useRegisterPasskey
 * passkeyの登録hooks
 * @returns
 */
export const useRegisterPasskey = () => {
  const { createPublicKeyCredential, isPasskeysAvailable } = useWebAuthn();

  /**
   * registerPasskey
   * Passkeyの登録処理
   */
  const registerPasskey = async (): Promise<
    PasskeySuccessResponse | PasskeyErrorDetails
  > => {
    try {
      const optionsJSON = await getCreationOptionsPasskeys();
      const publicKey = optionsJSON.data.publicKey;
      const options = parseCreationOptionsFromJSON(publicKey);

      const credential = await createPublicKeyCredential({
        publicKey: options,
      });
      if (!credential) {
        return {
          message: "Credential creation failed",
          code: "invalid_request",
        };
      }
      const registrationData = toRegistrationResponseJson(credential);
      const res = await postCreatePasskey(registrationData);
      AtlasTracking.createPasskey(res.data.id);
      return {
        code: "success",
        data: res.data,
      };
    } catch (e) {
      if (e instanceof DOMException) {
        if (e.name === "NotAllowedError" || e.name === "NotReadableError") {
          // ユーザーがキャンセル時のエラー
          AtlasTracking.closePasskeyPrompt("not_allowed");
          return { message: "Registration canceled", code: "user_cancel" };
        }
        if (e.name === "InvalidStateError") {
          // Authenticatorにパスキーがすでに登録されているなどAuthenticator側のエラー
          AtlasTracking.closePasskeyPrompt("already_registered");
          return { message: "Already registered", code: "already_registered" };
        }
        AtlasTracking.closePasskeyPrompt("webauthn_error", e);
        return {
          message: "Unknown Exception of navigator.credentials.create",
          code: "webauthn_error",
        };
      }
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        AtlasTracking.createPasskeyFailed(e);
        if (error.status === 403) {
          // 日経IDにログインしていない場合のエラー
          return {
            message: "Please log in to Nikkei ID",
            code: "login_required",
          };
        }
        return {
          message: "Network or server error occurred",
          code: error.status ? "invalid_request" : "server_error",
          data: error.response?.data,
        };
      }
      AtlasTracking.createPasskeyFailed(e);
      return { message: "Unknown error occurred", code: "unknown_error" };
    }
  };

  return {
    registerPasskey,
    isPasskeysAvailable,
  };
};
