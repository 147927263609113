import type React from "react";

import { Box } from "../../../foundations/Box";

import styles from "./ChevronRightIcon.module.css";

export const ChevronRightIcon: React.FC<React.ComponentProps<"svg">> = (
  props,
) => {
  const { className, ...others } = props;
  return (
    <Box className="nid-icon-container-tiny">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Icon of Chevron Right"
        aria-hidden="true"
        className={`${styles.gray90} ${className}`}
        {...others}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 3.63256L5.15301 3L8.25 6L5.15301 9L4.5 8.36744L6.94398 6L4.5 3.63256Z"
        />
      </svg>
    </Box>
  );
};
