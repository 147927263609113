import type React from "react";

import { Box } from "../../../foundations/Box";
import styles from "./ArrowForwardIcon.module.css";

export const ArrowForwardIcon: React.FC<
  React.ComponentProps<"svg"> & { color: "blue90" | "gray90" }
> = (props) => {
  const { className, ...others } = props;
  const color = styles[props.color];
  return (
    <Box className="nid-icon-container-large">
      <svg
        width={props.width || 24}
        height={props.height || 24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Arrow Forward"
        role="img"
        className={`${className} ${color}`}
        {...others}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.96973 8.03033L10.0304 6.96967L15.0607 12L10.0304 17.0303L8.96973 15.9697L12.9394 12L8.96973 8.03033Z"
        />
      </svg>
    </Box>
  );
};
